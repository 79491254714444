<template>
	<div class="ModifyInfo">
		<w-navTab titleText="修改信息"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<div class="nav"><w-title title="修改信息"></w-title></div>
			<div class="middle-item">
				<div class="middle-item-box">
					<div class="middle-item-left">所属队伍：</div>
					<!-- <div class="middle-item-right" @click="battalionShow = true"> -->
					<div class="middle-item-right">
						{{battalionValue}} <van-icon name="arrow-down" />
					</div>
				</div>
				<van-field v-model="name" label="您的昵称：" input-align="right" />
				<div class="middle-item-box">
					<div class="middle-item-left">血型：</div>
					<div class="middle-item-right" @click="bloodShow = true">
						{{bloodValue}} <van-icon name="arrow-down" />
					</div>
				</div>
				<van-field v-model="height" label="身高(厘米)：" input-align="right" />
				<van-field v-model="weight" label="体重(公斤)：" input-align="right" />
				<div class="middle-item-box">
					<div class="middle-item-left">党员：</div>
					<div class="middle-item-right">
						<van-radio-group v-model="party_member">
							<van-radio :name="1">是</van-radio>
							<van-radio :name="2">否</van-radio>
						</van-radio-group>
					</div>
				</div>
				<div class="middle-item-box">
					<div class="middle-item-left">退伍军人：</div>
					<div class="middle-item-right">
						<van-radio-group v-model="veteran">
							<van-radio :name="1">是</van-radio>
							<van-radio :name="2">否</van-radio>
						</van-radio-group>
					</div>
				</div>
				<van-field v-model="company" label="工作单位：" input-align="right" />
				<van-field v-model="specialty" label="专业：" input-align="right" />
				<van-field v-model="hobby" label="兴趣爱好：" input-align="right" />
				
				<div class="middle-item-box" @click="degreeShow = true">
					<div class="middle-item-left">学历：</div>
					<div class="middle-item-right">
						{{degreeValue}} <van-icon name="arrow-down" />
					</div>
				</div>
				<div class="middle-item-box" @click="jobShow = true">
					<div class="middle-item-left">职业：</div>
					<div class="middle-item-right">
						{{jobValue}} <van-icon name="arrow-down" />
					</div>
				</div>
				<div class="middle-item-box" @click="communityShow = true">
					<div class="middle-item-left">所属社区：</div>
					<div class="middle-item-right">
						{{communityValue}} <van-icon name="arrow-down" />
					</div>
				</div>
				
				<van-field type="textarea" autosize v-model="address" label="详细地址：" input-align="right" />
			</div>
			<template v-if="user.role_type > 1">
				<div class="middle-item">
					<van-field v-model="urgentName" label="紧急联系人：" input-align="right" />
					<van-field v-model="urgentTelephone" label="紧急联系电话：" input-align="right" />
				</div>
				<div class="middle-item-bottom">
					<div class="middle-item-title">服务证件照上传：</div>
					<div class="uploader">
						<div class="uploader-item" v-if="pictureUrl">
							<img class="uploader-close" src="@/assets/img/uploader_icon_close.png"
								@click="deletePicture" />
							<img class="uploader-image" :src="pictureUrl" />
						</div>
						<div class="uploader-item uploader-item-select" v-else>
							<van-uploader :after-read="onSelect" v-show="isShowUploader" upload-icon="plus"
								:max-count="1"></van-uploader>
						</div>
					</div>
				</div>
			</template>
			<div class="middle-footer">
				<van-button v-if="districtType() == 1" type="primary" round color="#3377FF" block
					@click="onPoint">提交修改</van-button>
				<van-button v-if="districtType() == 2" type="primary" round color="#ff6969" block
					@click="onPoint">提交修改</van-button>
			</div>
			<van-popup v-model="bloodShow" position="bottom" :style="{ height: '40%' }">
				<van-picker title="标题" show-toolbar :columns="columns" @confirm="onConfirm"
					@cancel="bloodShow = !bloodShow" />
			</van-popup>

			<van-popup v-model="battalionShow" position="bottom" :close-on-click-overlay="false">
				<van-cascader v-model="battalionValue" title="请选择所属队伍" :options="navColumns" :value="levelValue"
					@close="onClose" @finish="onFinish" />
			</van-popup>
			<!-- 选择学历 -->
			<van-popup v-model="degreeShow" position="bottom" :style="{ height: '40%' }">
				<van-picker title="选择学历" show-toolbar :columns="degreeColumns" @confirm="onDegreeConfirm"
					@cancel="degreeShow = !degreeShow" />
			</van-popup>
			<!-- 选择职业 -->
			<van-popup v-model="jobShow" position="bottom" :style="{ height: '40%' }">
				<van-picker title="选择职业" show-toolbar :columns="jobColumns" @confirm="onJobConfirm"
					@cancel="jobShow = !jobShow" />
			</van-popup>
			<!-- 选择社区 -->
			<van-popup v-model="communityShow" position="bottom">
				<van-cascader v-model="communityValue" title="请选择所属社区" :options="communityList"
					@close="communityShow = !communityShow" @finish="onCommunityChange" />
			</van-popup>
		</div>
	</div>
</template>

<script>
	import IndexApi from '@/api/index';
	import Vant from '@/vendor/vant.js';
	import UserApi from '@/api/user';
	import UploadApi from '@/api/upload';
	import WTitle from '@/components/Title';
	import ValidatorVendor from '@/vendor/validator';
	export default {
		inject: ['className', 'districtType', 'themeColor'],
		name: 'ModifyInfo',
		data() {
			return {
				loadingPage: true,
				user: '',
				name: '',
				telephone: '',
				urgentName: '',
				urgentTelephone: '',
				address: '',
				pictureUrl: '',
				picturePath: '',
				isShowUploader: true,
				UserInfo: '',
				bloodShow: false,
				columns: ['A', 'B', 'AB', 'O', 'RH'],
				bloodValue: '请选择你的血型',
				blood_type: '',
				height: '',
				weight: '',
				party_member: 1,
				veteran: 1,
				job: '',
				company: '',
				specialty: '',
				hobby: '',
				battalionShow: false,
				battalionId: '',
				battalionValue: '请选择所属队伍',
				navColumns: [],

				degreeShow: false,
				degreeValue: '请选择学历',
				degreeColumns: [],

				jobShow: false,
				jobValue: '请选择职业',
				jobColumns: [],

				communityShow: false,
				communityValue: '请选择所属社区',
				communityList: [],
				countyId: '',
				streetId: '',
				communityId: '',
				
				level_1_id: '',
				level_2_id: 0,
				level_3_id: 0,
				level_4_id: 0,
				levelValue: 0,
				districtId: '',
				
				levelText: ''
			};
		},
		created() {
			UserApi.getUserInfo().then(result => {
				this.user = result.data;
				this.name = result.data.nickname;
				this.telephone = result.data.telephone;
				this.urgentName = result.data.emergency_contact;
				this.urgentTelephone = result.data.emergency_telephone;
				this.bloodValue = this.columns[result.data.blood_type - 1] || '请选择你的血型';
				this.blood_type = result.data.blood_type;
				this.jobValue = result.data.job;
				this.height = result.data.height;
				this.weight = result.data.weight;
				this.party_member = result.data.party_member;
				this.veteran = result.data.veteran;
				this.company = result.data.company;
				this.specialty = result.data.specialty;
				this.hobby = result.data.hobby;
				this.address = result.data.address;
				this.pictureUrl = result.data.police_photo;
				this.picturePath = result.data.police_photo;
				this.battalionId = result.data.department_id
				this.battalionValue = result.data.level_text
				this.levelText = result.data.level_text
				this.degreeValue = result.data.edu_level
				this.communityValue = result.data.area_text
				this.countyId = result.data.county_id
				this.streetId = result.data.street_id
				this.communityId = result.data.community_id
				this.level_1_id = result.data.level_1_id
				this.level_2_id = result.data.level_2_id
				this.level_3_id = result.data.level_3_id
				this.level_4_id = result.data.level_4_id
				this.districtId = result.data.district_id
				this.getBattalion()
				this.loadingPage = false
			});
			this.validator = new ValidatorVendor({
				rules: {
					nickname: {
						required: true
					}
				},
				messages: {
					nickname: {
						required: '昵称不能为空'
					}
				}
			});
			this.validator.addRules({
				rules: {
					emergency_contact: {
						required: true
					},
					emergency_telephone: {
						required: true,
						mobile: true
					},
					address: {
						required: true
					},
					police_photo: {
						required: true
					},
					blood_type: {
						required: true
					},
					height: {
						required: true
					},
					weight: {
						required: true
					},
					party_member: {
						required: true
					},
					veteran: {
						required: true
					},
					job: {
						required: true
					},
					company: {
						required: true
					},
					edu_level:{
						required: true
					}
				},
				messages: {
					emergency_contact: {
						required: '紧急联系人不能为空'
					},
					emergency_telephone: {
						required: '紧急联系电话不能为空',
						mobile: '手机号格式错误'
					},
					address: {
						required: '地址不能为空'
					},
					police_photo: {
						required: '服务证件照不能为空'
					},
					blood_type: {
						required: '血型不能为空'
					},
					height: {
						required: '身高不能为空'
					},
					weight: {
						required: '体重不能为空'
					},
					job: {
						required: '职业不能为空'
					},
					company: {
						required: '工作单位不能为空'
					},
					edu_level:{
						required: '学历不能为空'
					}
				}
			});
			this.getLevelList()
			this.getJobList()
			this.getAddress()
			
		},
		methods: {
			// 社区确认
			onCommunityChange({selectedOptions}){
				this.communityShow = !this.communityShow
				this.communityValue = selectedOptions[0].text + '-' + selectedOptions[1].text + '-' + selectedOptions[2].text
				this.countyId = selectedOptions[0].value
				this.streetId = selectedOptions[1].value
				this.communityId = selectedOptions[2].value
			},
			// 学历列表
			getLevelList(){
				UserApi.levelList()
					.then(result => {
						this.degreeColumns = result.data
					})
					.catch(error => {
						Vant.Toast(error.msg);
					});
			},
			// 职业接口
			getJobList(){
				UserApi.jobList()
					.then(result => {
						this.jobColumns = result.data
					})
					.catch(error => {
						Vant.Toast(error.msg);
					});
			},
			// 获取地址子集
			getAddress(){
				UserApi.address()
					.then(result => {
						this.communityList = result.data
					})
					.catch(error => {
						Vant.Toast(error.msg);
					});
			},
			// 学历确认
			onDegreeConfirm(value, index) {
				this.degreeShow = !this.degreeShow
				this.degreeValue = value
			},
			// 职业确认
			onJobConfirm(value, index) {
				this.jobShow = !this.jobShow
				this.jobValue = value
			},
			// 获取所属队伍
			getBattalion() {
				UserApi.subDepartments({id: this.districtId})
					.then(result => {
						this.navColumns = result.data
					})
					.catch(error => {
						Vant.Toast(error.msg);
					});
			},
			onConfirm(value, index) {
				this.bloodValue = value
				this.blood_type = index + 1
				this.bloodShow = !this.bloodShow
			},
			onPoint() {
				if(typeof(this.battalionValue) == 'number'){
					return Vant.Toast('所属队伍选择错误');
				}
				let params = {
					nickname: this.name,
					blood_type: this.blood_type,
					height: this.height,
					weight: this.weight,
					party_member: this.party_member,
					veteran: this.veteran,
					job: this.jobValue,
					company: this.company,
					specialty: this.specialty,
					hobby: this.hobby,
					emergency_contact: this.urgentName,
					emergency_telephone: this.urgentTelephone,
					address: this.address,
					police_photo: this.picturePath,
					department_id: this.battalionId,
					edu_level: this.degreeValue,
					county_id: this.countyId,
					street_id: this.streetId,
					community_id: this.communityId,
					area_text: this.communityValue,
					level_1_id: this.level_1_id,
					level_2_id: this.level_2_id,
					level_3_id: this.level_3_id,
					level_4_id: this.level_4_id,
				};
				if (!this.validator.checkData(params)) {
					return;
				}
				UserApi.updateInfo(params)
					.then(result => {
						Vant.Toast.success('修改成功');
						setTimeout(() => {
							this.$router.push({
								name: 'User'
							});
						}, 1000);
					})
					.catch(error => {
						Vant.Toast(error.msg);
					});
			},
			deletePicture() {
				this.pictureUrl = '';
				this.picturePath = '';
			},
			onSelect(selected) {
				Vant.Toast.loading('上传中');

				UploadApi.image(selected.file)
					.then(result => {
						this.pictureUrl = result.data.fileUrl;
						this.picturePath = result.data.filePath;
					})
					.catch(error => {
						Vant.Toast.fail(error.msg);
					})
					.finally(() => {
						Vant.Toast.clear();
					});
			},
			onFinish(e) {
				let options = e.selectedOptions
				this.battalionShow = false
				
				if(options.length == 1){
					this.battalionValue = options[0].text
					this.battalionId = options[0].value
					this.level_1_id = options[0].value
					this.level_2_id = 0
					this.level_3_id = 0
					this.level_4_id = 0
					
					this.levelValue = options[0].value
				}else if(options.length == 2){
					this.battalionValue = options[0].text + '-' + options[1].text
					this.level_1_id = options[0].value
					this.level_2_id = options[1].value
					this.level_3_id = 0
					this.level_4_id = 0
					
					this.battalionId = options[1].value
					this.levelValue = options[1].value
				}else if(options.length == 3){
					this.battalionValue = options[0].text + '-' + options[1].text + '-' + options[2].text
					this.level_1_id = options[0].value
					this.level_2_id = options[1].value
					this.level_3_id = options[2].value
					this.level_4_id = 0
					
					this.battalionId = options[2].value
					this.levelValue = options[2].value
				}else if(options.length == 4){
					this.battalionValue = options[0].text + '-' + options[1].text + '-' + options[2].text + '-' + options[3].text
					this.level_1_id = options[0].value
					this.level_2_id = options[1].value
					this.level_3_id = options[2].value
					this.level_4_id = options[3].value
					
					this.battalionId = options[3].value
					this.levelValue = options[3].value
				}
			},
			onClose(){
				this.battalionShow = false
				this.battalionValue = this.levelText
			}
		},
		components: {
			WTitle
		}
	};
</script>

<style lang="less" scoped>
	.ModifyInfo.city {
		/deep/.van-tabs__line {
			background-color: #3377ff;
		}
	}

	.ModifyInfo.district {
		/deep/.van-tabs__line {
			background-color: #ff6969;
		}
	}

	.ModifyInfo {
		position: relative;
		overflow: hidden;

		.container {
			padding: 0 10px 64px 10px;
			box-sizing: border-box;

			.nav {
				padding: 13px 0 12px 10px;
			}

			.middle-item {
				background: #ffffff;
				border-radius: 8px;
				overflow: hidden;
				margin-bottom: 10px;

				.van-cell {
					font-size: 16px;
				}

				/deep/.van-field__label {
					width: 120px;
				}

				/deep/.van-field__body {
					flex-wrap: wrap;
				}

				.middle-item-box {
					display: flex;
					justify-content: space-between;
					margin: 0 16px;
					padding: 10px 0;
					border-bottom: 1px solid #ebedf0;
					box-sizing: border-box;

					.middle-item-left {
						font-size: 16px;
						color: #646566;
						min-width: 90px;
					}

					.middle-item-right {
						display: flex;
						align-items: center;

						input {
							background-color: #ffffff;
							text-align: right;
							border: 0;

						}

						.van-radio-group {
							display: flex;

							.van-radio {
								margin-left: 10px;
							}
						}
					}
				}
			}

			.middle-footer {
				width: 100%;
				position: absolute;
				padding-right: 20px;
				bottom: 20px;
				box-sizing: border-box;

				/deep/.van-button--round {
					border-radius: 8px;
					font-size: 18px;
				}
			}

			.middle-item-bottom {
				background: #ffffff;
				border-radius: 8px;
				overflow: hidden;
				margin-bottom: 10px;
				padding: 12px 18px;

				.middle-item-title {
					font-size: 16px;
					color: #646566;
					margin-bottom: 10px;
				}

				.uploader {
					display: flex;

					.uploader-item {
						position: relative;
						margin-right: 12px;

						&:last-child {
							margin-right: 0;
						}

						.uploader-close {
							position: absolute;
							top: 5px;
							right: 5px;
							width: 16px;
							height: 16px;
						}

						.uploader-image {
							width: 75px;
							height: 75px;
							object-fit: cover;
							border-radius: 6px;
						}
					}

					.uploader-item-select {
						position: relative;

						/deep/ .van-uploader__upload {
							width: 75px;
							height: 75px;
							margin: 0;
							border-radius: 6px;
							background-color: #f2f2f2;

							.van-icon {
								font-size: 30px;
								color: #aaa;
							}
						}
					}
				}
			}
		}
	}
</style>